@import "../../global";

/* ---------- Mobile, START ---------- */

@mixin cart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  background-color: $color-white;
  box-shadow: 0 14px 20px rgba(0, 0, 0, 0.15);
  z-index: 3;
}

.mobile {
  @include cart;
}

.cartIcon {
  font-size: 33px;
  color: $color-deepGreen;
}

.desktop {
  display: none;
}

.countLittle {
  position: absolute;
  right: -3px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  color: $color-white;
  background-color: $color-green;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  .button {
    display: flex;
    height: 40px;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    color: $color-white;
    font-size: $size-small;
    font-family: Montserrat, OpenSans_400, sans-serif;
    background: $color-green;
    box-shadow: rgba(63, 148, 68, 0.3) 0 5px 15px;
    &:hover {
      background: $color-deepGreen;
    }
    &:active {
      background: $color-green;
      color: $color-mediumGreen;
    }
  }

  .divider {
    margin: 0 10px;
  }

  .arrow {
    margin: 3px 0 0 0;
  }

  .arrowCount svg {
    position: relative;
    left: -15px;
    transition: 0.25s ease-in;
  }

  .count {
    position: relative;
    top: -20px;
    left: 0;
    transition: 0.25s ease-in;
  }

  .arrowCount {
    display: block;
    float: right;
    overflow: hidden;
    width: 15px;
    height: 16px;
  }

  .button:hover svg {
    left: 0px;
    transition: 0.25s ease-in;
  }

  .button:hover .count {
    left: 15px;
    transition: 0.25s ease-in left;
  }
}
/* ---------- Desktop, END ---------- */
