@import "../../global";

/* ---------- Mobile, START ---------- */
.icon {
  width: 16px;
  height: 16px;
  margin: 2px 0 0 5px;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}

@media screen and (min-width: 1420px) {
}

/* ---------- Desktop, END ---------- */
