@import '../../global';

/* ---------- Mobile, START ---------- */
.container {
  margin: 20px 0;
  min-height: 700px;
  font-family: 'Montserrat', 'OpenSans_400', sans-serif;
}

.article {
  margin: 20px 15px;
}

.articleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 5px 0;
  border-bottom: 1px solid $color-lightSilver;
}

.articleTitle {
  font-weight: $weight-bold;
  font-size: $size-lMedium;
  color: $color-black;
}

.articleTime {
  font-weight: $weight-normal;
  font-size: $size-small;
  color: $color-silver;
}

.articleImaWrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}

.articleContent {
  margin: 20px 0 0 0;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .container {
    margin: 0 0 30px 0;
    min-height: 1000px;
  }

  .article {
    margin: 30px 5%;
    max-width: 1900px;
  }
}
/* ---------- Desktop, END ---------- */
