@import "../../../../global";

/* ---------- Mobile, START ---------- */
.container {
}
.title {
  text-align: center;
  margin: 15px 0;
  width: 100%;
  color: $color-black;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
}

.payMethod li.current {
  color: $color-white !important;
  background: $color-green;
  box-shadow: 0 5px 15px rgba(53, 148, 68, 0.5);
}

.current {
  background: $color-green;
  color: $color-white;
}

.payMethod li {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33% - 10px) !important;
  padding: 16px;
  margin: 0 10px 20px 0;
  text-align: center;
  font-size: $size-xSmall;
  color: $color-darkSilver;
  background: $color-white;
  border-radius: 10px;
  font-family: "OpenSans_400", sans-serif;
  box-shadow: 0 5px 20px rbga(6, 5, 50, 0.1);
}

.payTitle {
  display: block;
  margin: 0 0 8px 0;
  font-size: $size-small;
  font-family: "OpenSans_400", sans-serif;
}

.payMethod {
  display: flex;
  padding: 0 0 0 10px;
  justify-content: space-between;
}

.iconPay {
  font-size: $size-medium;
  margin: 0 0 4px 0;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */

/* ---------- Desktop, END ---------- */
