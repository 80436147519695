@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  background-color: $color-black-transparent;
  transition: 1s;
}


.close {
  margin: 0 87% 0 0;
  &:hover {
    transform: rotate(180deg);
    transition: transform 500ms ease 0s;
  }
  transform: rotate(0deg);
  transition: transform 500ms ease 0s;
}

.closeIcon {
  color: $color-white;
}

.content {
  position: absolute;
  overflow-y: scroll;
  right: 0;
  height: 100%;
  width: 85%;
  background: $color-lightSilver;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .content {
    position: absolute;
    overflow-y: scroll;
    right: 0;
    height: 100%;
    width: 420px;
    background: $color-lightSilver;
  }

  .close {
    margin: 0 430px 0 0;
  }
}
/* ---------- Desktop, END ---------- */
