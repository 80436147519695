@import "../../../../global";

/* ---------- Mobile, START ---------- */
.container {
  background: $color-white;
  padding: 30px 15px;
  margin: 10px 0 0 0;
}

.title {
  text-align: center;
  margin: 0 0 15px 0;
  font-size: $size-small;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
}

.choise {
  display: flex;
  justify-content: space-between;
  font-family: "OpenSans_400", sans-serif;
  border-radius: 19px;
  background: $color-lightSilver;
  padding: 2px;
  margin: 0 0 14px 0;
}
.choise li {
  flex-grow: 1;
  padding: 8px 10px;
  text-align: center;
  border-radius: 15px;
  font-size: $size-small;
  line-height: 14px;
}
.choise .current {
  background: $color-green;
  color: $color-white;
}

.select {
  width: 100%;
}

.option {
  width: 100%;
}

.inputName,
.inputPhone,
.inputAddress,
.inputTime,
.comment,
.select
{
  margin: 0 0 12px 0;
  box-sizing: border-box;
  width: 100%;
  background: $color-lightSilver;
  color: $color-darkSilver;
  border: 0;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
  line-height: $size-lSmall;
  padding: 8px 16px;
  border-radius: 16px;
}

.inputName:focus,
.inputPhone:focus,
.inputAddress:focus,
.inputTime:focus,
.comment:focus
{
  outline: 2px solid $color-green;
}

.select{
  appearance: none;
  outline: 0px;
  background: url('../../../../assets/icons/arrow_down.svg') no-repeat right $color-lightSilver;
}


.timeDelivery {
  display: flex;
  align-items: baseline;
  font-size: $size-small;
  color: $color-darkSilver;
  font-family: "OpenSans_400", sans-serif;
}

.timeDelivery li {
  margin: 8px 20px 20px 0;
}

.timeDelivery .inputTime {
  margin-left: auto;
  display: block;
  width: 100px;
}

.timeDelivery span{
  margin: 0 0 0 8px;
}

.timeDelivery input+span::before {
  content:'';
  position: absolute;
  margin: -19px 0 0 0;
  display: block;
  position: absolute;
  width: 21px;
  height:21px;
  background: url('../../../../assets/icons/check_box_blank.svg');
}

.timeDelivery input:checked+span::before{
  background: url('../../../../assets/icons/check_box.svg');
}

.timeDelivery input {
  opacity: 0;
}

.timeDelivery .inputTime{
  opacity: 1;
}


.timeDelivery input+label::before {
  content:'';
  display: block;
  position: absolute;
  width: 21px;
  height:21px;
  background: url('../../../../assets/icons/check_box_blank.svg');
}
.timeDelivery label::before{
  background: url('../../../../assets/icons/check_box_blank.svg');
}

/* Cutlery Start */

.cutlery {
  margin: 4px 0 6px 0;
  display: flex;
  align-items: center;
  font-size: $size-small;
  font-family: "OpenSans_400", sans-serif;
}

.quantity {
  display: flex;
  margin: 0 0 0 15px;
}

.quantity :first-child {
  background: $color-lightSilver;
  color: $color-lightGray;
  border: 0;
  font-size: $size-medium;
  line-height: 32px;
  padding: 0 10px 0 14px;
  border-radius: 16px 0 0 16px;
  &:hover {
    color: $color-black;
  }
  &:focus {
    color: $color-darkSilver;
  }
}

.quantity :last-child {
  background: $color-lightSilver;
  border: 0;
  font-size: $size-medium;
  line-height: 32px;
  padding: 0 14px 0 10px;
  border-radius: 0 16px 16px 0;
  color: $color-darkSilver;
  &:hover {
    color: $color-black;
  }
  &:focus {
    color: $color-darkSilver;
  }
}

.quantityInput {
  background: $color-lightSilver;
  border: 0;
  text-align: center;
  width: 36px;
  line-height: 32px;
  color: $color-darkSilver;
}


.nomeals
{
  display: none;
}

.icon{
  position: absolute;
  color: $color-green;
  margin: 0 5px 0 0;
}
.label {
  margin: 0 0 0 22px;
}

/* Cutlery End */

.required {
  display: block;
  padding: 15px 0 0 0;
  font-size: $size-small;
  color: $color-silver;
  font-family: "OpenSans_400", sans-serif;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */

/* ---------- Desktop, END ---------- */
