@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  position: fixed;
  z-index: 4;
  overflow: scroll;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
}

.link {
  display: none;
}

.close {
  display: none;
}

.contant {
  height: 100%;
}

.dawn {
  position: absolute;
  top: 0;
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 15px;
  border-radius: 30px;
  text-decoration: none;
  font-size: $size-large;
  color: $color-black;
  z-index: 9;
  background: $color-white;
  box-shadow: 0 5px 15px rgba(92, 99, 112, 0.2);
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: $color-black-transparent;
  }

  .contant {
    overflow: hidden;
    width: 924px;
    height: 630px;
    position: relative;
    margin: 0;
    border-radius: 25px;
    background-color: $color-white;
  }

  .link {
    display: block;
    position: fixed;
    margin: 15px 0 0 930px;
    font-size: $size-large;
    color: $color-white;
    z-index: 9;
  }

  .close {
    margin: -10px 0 0 0;
    display: block;
    &:hover {
      transform: rotate(180deg);
      transition: transform 500ms ease 0s;
    }
    transform: rotate(0deg);
    transition: transform 500ms ease 0s;
  }

  .dawn {
    display: none;
  }
}

/* ---------- Desktop, END ---------- */
