@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  flex-direction: column;
  background: $color-white;
  width: 100%;
}

.leftBlock {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start
}

.leftBlockTitle{
  margin: 30px 16px 0 200px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-lMedium;
  line-height: 1.3;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  overflow-y: hidden;
  height: 350px;
  width: auto;
  position: fixed;
  top: 0;
  z-index: 1;
}

.badgeWrapper {
  position: absolute;
  top: 17%;
  right: 12%;
  z-index: 2;
}

.dishes {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 25px 0;
  margin: 0 0 -300px 0;
}
.nameleft{
  margin: 4px 0 0 0;
  font-family: "Open Suns", sans-serif;
}

.dish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 0;
  width: 165px;
}

.images {
  box-sizing: border-box;
  width: 135px;
  height: 135px;

  border-radius: 10px;
}

.imagesActive {
  box-sizing: border-box;
  width: 135px;
  height: 135px;
  border: 2px solid $color-green;
  border-radius: 10px;
}

.nameLeft {
  text-align: center;
  margin: 5px 0 0 0;
  max-width: 140px;
  max-height: 25px;
  overflow: hidden;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-xSmall;
}

.price {
  margin: 8px 0 0 0;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-xSmall;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 12px 0;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-lMedium;
  line-height: 1.3;
}

.cover {
  width: 100%;
}

.infoBlock {
  position: relative;
  z-index: 2;
  margin: 300px 0 0 0;
  padding: 0 15px 0 15px;
  background-color: $color-white-transparent;
  backdrop-filter: blur(20px);
}

.hide {
  display: none;
}

.description {
  font-size: $size-small;
  line-height: 20px;
  font-family: "OpenSans_400", sans-serif;
}

.ingredientsTitle {
  display: flex;
  margin: 22px 0 10px 0;
  font-size: $size-lSmall;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
}

.addtoCart {
  padding: 12px 0;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: $size-lSmall;
  background: $color-green;
  box-shadow: 0 5px 15px rgba(53, 148, 68, 0.5);
  color: $color-white;
  border-radius: 25px;
  border: none;
  transition: 0.1s ease-in;
}
.addtoCart:hover {
  background: $color-deepGreen;
  transition: 0.1s ease-in;
}

.addtoCart:active {
  color: $color-mediumGreen;
}

.comboCard {
  height: auto;
  margin: 10px 0 0 0;
  padding: 15px;
  border-radius: 10px;
  background: $color-white;
  box-shadow: 0 5px 15px rgba(92, 99, 112, 0.15);
}

.infoWrapper{
  display: flex;
}

.comboCard .pizzaSide{
  display: none;
}

.comboCardActive .pizzaSide{
  display: block;
}

.comboCardActive .sideChoise {
  display: none;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
}

.buttonFooter{
  background: rgba(255, 255, 255, 0.7);
  position: sticky;
  bottom: 0;
  z-index: 3;
  padding: 15px 15px 15px 18px;
}

.sideChoise {
  color: $color-darkSilver;
  font-size: $size-xSmall;
  font-family: "OpenSans_400", sans-serif;
  line-height: 1.2;
  margin: 0 0 8px 0;
}

.comboCardActive {
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 13px;
  border-radius: 10px;
  border: 2px solid $color-green;
  box-shadow: 0 5px 15px rgba(92, 99, 112, 0.15);
}

.imageContainer {
  width: 100px;
  height: auto;
  display: flex;
  align-self: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 13px 0 0;
}

.comboInfo {
  width: 100%;
}

.comboImage {
  height: auto;
  width: 100%;
}

.comboName {
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-small;
  line-height: 1.5;
  margin: 0 0 4px 0;
}

.comboDescription {
  color: $color-darkSilver;
  font-size: $size-xSmall;
  font-family: "OpenSans_400", sans-serif;
  line-height: 1.5;
  margin: 0 0 12px 0;
}

.pizzaSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 0 0;
  color: $color-darkSilver;
  font-size: $size-xSmall;
  font-weight: $weight-semiBold;
  font-family: "OpenSans_400", sans-serif;
  line-height: 1.5;
}

.boards {
  display: flex;
  flex-direction: row;
}

.boardsTitle {
  margin: 3px 3px 0 0;
  font-size: $size-xSmall;
  font-weight: $weight-semiBold;
  font-family: "OpenSans_400", sans-serif;
  line-height: 1.5;
}

.boardsIcon {
  margin: 5px 0 0 8px;
}

.button {
  padding: 5px 15px;
  font-family: "Montserrat", sans-serif;
  font-size: $size-xSmall;
  background: $color-lightGreen;
  color: $color-green;
  border-radius: 25px;
  border: none;
  transition: 0.1s ease-in;
}
.button:hover {
  color: $color-white;
  background: $color-green;
  transition: 0.1s ease-in;
}

.button:active {
  color: $color-mediumGreen;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {

  .container {
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .leftBlock {
    margin: 0;
    padding: 0 15px 0 15px;
    max-width: 500px;
  }

  .leftBlockTitle {
    display: none;
  }

  .image {
    width: 100%;
    height: auto;
    position: static;
  }

  .dishes {
    margin: 0;
    width: 470px;
  }

  .dish {
    width: 150px;
    height: 180px
  }

  .rightBlock{
    display: flex;
    flex-direction: column;
    width: 717px;
    height: 100%;
    justify-content: space-between;
  }

  .cover {
    overflow-y: scroll;
    border-radius: 0 20px 20px 0;
  }

  .title {
    color: $color-black;
    padding: 0 0 12px 0;
    font-size: $size-XlMedium;
  }

  .infoBlock {
    margin: 0;
    padding: 25px 15px 10px 15px;
    width: 370px;
    backdrop-filter:none;
  }

  .footer {
    position: relative;
    box-sizing: border-box;
    z-index: 15;
    width: 100%;
    padding: 15px 30px;
  }

  .buttonFooter{
    padding: 15px 49px 15px 16px;
  }
}
/* ---------- Desktop, END ---------- */
