@import "../../global";

/* ---------- Mobile, START ---------- */
.wrapper {
  margin: -60px 0 0 0;
  min-height: 1000px;
}

.title {
  font-family: "OpenSans_400", sans-serif;
  font-weight: $weight-bold;
  font-size: $size-lMedium;
  color: $color-black;
  padding: 60px 0 10px 15px;
}

.block {
  display: flex;
  flex-direction: column;
  &:first-child {
    margin: -5px 0 0 0;
  }
}

.dish {
  position: relative;
  width: 100%;
  // padding: 5px 0;
  border-bottom: 1px solid $color-lightSilver;
}

.dishContainer {
  display: flex;
  height: 150px;
}

.imageWrapper {
  flex-basis: 120px;
  align-self: center;
  position: relative;
}

.image {
  width: 145px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 0 0 7px;
}

.name {
  font-family: "OpenSans_400", sans-serif;
  font-weight: $weight-bold;
  font-size: $size-small;
  color: $color-black;
  margin: 10px 0 0 0;
}

.description {
  height: 60px;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-xSmall;
  line-height: 1.5;
  color: $color-silver;
}

.oldPrice {
  width: auto;
  text-align: center;
  margin: 6px 4px 0 0;
  padding: 0 10px 10px;
  border-radius: 0;
  color: $color-darkGreen;
  font-size: $size-small;
  text-decoration: line-through;
}

.price {
  display: block;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  min-width: 100px;
  text-align: center;
  padding: 4px 10px 0 10px;
  line-height: 30px;
  border-radius: 20px;
  color: $color-darkGreen;
  background-color: $color-lightGreen;
}

.newPrice {
  display: block;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  min-width: 100px;
  text-align: center;
  padding: 4px 10px 0 10px;
  line-height: 30px;
  border-radius: 20px;
  color: $color-white;
  background-color: $color-lightOrange;
}

.priceHalves {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  height: 30px;
  width: 100px;
  border-radius: 20px;
  color: $color-white;
  background-color: $color-green;
}

.price:hover {
  color: $color-white;
  background-color: $color-green;
  transition: 0.1s ease-in;
}

.priceHalves:hover {
  color: $color-white;
  background-color: $color-darkGreen;
  transition: 0.1s ease-in;
}

.addToCart,
.addToCartHalves {
  display: none;
  cursor: pointer;
}

.link {
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

.purchase {
  display: flex;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.addToShoppingCart {
  display: block;
  height: 38px;
  border-radius: 20px;
  padding: 0 14px;
  margin: 0 0 0 10px;
  border: 0;
  color: $color-darkGreen;
  background-color: $color-lightGreen;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  font-weight: $weight-boldNormal;
  font-size: $size-small;
  transition: 0.2s ease-in;
  &:hover {
    color: $color-white;
    background-color: $color-green;
    transition: 0.2s ease-in;
  }
  cursor: pointer;
}
.addToNewShoppingCart {
  display: block;
  height: 38px;
  border-radius: 20px;
  padding: 0 14px;
  margin: 0 0 0 10px;
  border: 0;
  color: $color-darkGreen;
  background-color: $color-lightOrange;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  font-weight: $weight-boldNormal;
  font-size: $size-small;
  transition: 0.2s ease-in;
  &:hover {
    color: $color-white;
    background-color: $color-green;
    transition: 0.2s ease-in;
  }
  cursor: pointer;
}

.shoppingNewCart {
  color: $color-white;
}

.addToNewCart {
  display: none;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .imageWrapper {
    flex-basis: 150px;
  }

  .image {
    width: 150px;
  }
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) {
  .imageWrapper {
    flex-basis: 150px;
  }

  .image {
    width: 150px;
  }
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .container {
    padding: 0 5%;
    margin: 0;
  }

  .title {
    margin: 20px 5% 0 4%;
  }

  .block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .dish {
    display: flex;
    cursor: pointer;
    width: 22.9%;
    margin-right: 26px;
    padding: 0 0 20px 0;
  }

  .dishContainer {
    flex-direction: column;
    height: 100%;
  }

  .imageWrapper {
    text-align: center;
    position: relative;
    transition: 0.2s ease-in;
  }

  .dish:hover .imageWrapper {
    transition: 0.3s ease-in;
    transform: scale(1.1);
  }

  .image {
    width: 90%;
    margin: 5% 0 0 0;
  }

  .info {
    height: 100%;
    justify-content: space-between;
    margin: 10px 0 0 0;
    margin: 0 0 0 20px;
  }

  .name {
    font-size: 16px;
    color: $color-black;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
  }

  .description {
    font-size: $size-small;
    color: $color-silver;
    margin: 10px 0;
    max-height: 78px;
    overflow: hidden;
    line-height: 20px;
  }

  .purchase {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .oldPrice {
    width: auto;
    text-align: center;
    padding: 0;
    border-radius: 0;
    color: $color-silver;
    font-size: $size-lSmall;
    background-color: $color-white;
    text-decoration: line-through;
  }

  .price {
    width: auto;
    text-align: center;
    margin: 5px 0 0 0;
    padding: 0;
    border-radius: 0;
    color: $color-black;
    font-size: $size-lMedium;
    background-color: $color-white;
  }

  .newPrice {
    width: auto;
    text-align: center;
    margin: 5px 0 0 0;
    padding: 0;
    border-radius: 0;
    color: $color-black;
    font-size: $size-lMedium;
    background-color: $color-white;
  }

  .priceHalves {
    width: auto;
    text-align: center;
    margin: 5px 0 0 0;
    border-radius: 0;
    color: $color-black;
    font-size: $size-lMedium;
    background-color: $color-white;
  }

  .price:hover,
  .priceHalves:hover {
    color: $color-black;
    background-color: $color-white;
  }

  .addToCart {
    display: block;
    height: 38px;
    border-radius: 20px;
    padding: 0 25px;
    border: 0;
    color: $color-darkGreen;
    background-color: $color-lightGreen;
    font-family: "Montserrat", "OpenSans_400", sans-serif;
    font-weight: $weight-boldNormal;
    font-size: $size-small;
    transition: 0.2s ease-in;
    &:hover {
      color: $color-white;
      background-color: $color-green;
      transition: 0.2s ease-in;
    }
  }

  .addToNewCart {
    display: block;
    height: 38px;
    border-radius: 20px;
    padding: 0 25px;
    border: 0;
    color: $color-white;
    background-color: $color-lightOrange;
    font-family: "Montserrat", "OpenSans_400", sans-serif;
    font-weight: $weight-boldNormal;
    font-size: $size-small;
    transition: 0.2s ease-in;
    &:hover {
      color: $color-white;
      background-color: $color-green;
      transition: 0.2s ease-in;
    }
  }

  .addToCartHalves {
    display: block;
    height: 38px;
    border-radius: 20px;
    padding: 0 25px;
    border: 0;
    color: $color-white;
    background-color: $color-green;
    font-family: "Montserrat", "OpenSans_400", sans-serif;
    font-size: $size-small;
    transition: 0.1s ease-in;
  }

  .addToCartHalves:hover {
    color: $color-white;
    background-color: $color-darkGreen;
    transition: 0.1s ease-in;
  }
}
/* ---------- Desktop, END ---------- */
