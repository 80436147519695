@import '../../global';

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 214px;
}

.title {
  font-family: 'Montserrat', 'OpenSans_400', sans-serif;
  font-weight: $weight-boldNormal;
  font-style: normal;
  font-size: $size-lMedium;
  padding: 20px 2.5% 0 2.5%;
}

.block {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.emptyCombo {
  width: 150px;
  margin: 0 0 0 1%;
}

.name {
  font-family: 'Montserrat', 'OpenSans_400', sans-serif;
  font-size: $size-medium;
  font-weight: $weight-semiBold;
}

.item {
  display: flex;
  align-items: center;
  width: 280px;
  height: 80px;
  padding: 10px 12px;
  border-radius: 12px;
  margin: 10px;
  box-shadow: rgba(6, 5, 15, 0.15) 0 2px 15px -3px;
  &:hover {
    transition: 0.3s ease-in;
    transform: scale(1.02);
    box-shadow: rgba(94, 93, 95, 0.15) 0 2px 15px -3px;
  }
  transition: 0.2s ease-in;
}

.image {
  margin: 3px 8px 0 0;
  width: 100px;
  height: 100px;
}

.info {
  text-decoration: none;
  margin: 0 0 0 40px;
  font-family: 'Montserrat', 'OpenSans_400', sans-serif;
  font-size: $size-small;
  line-height: 21px;
}

.link {
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: $color-black;
  }
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .title {
    padding: 20px 5% 0 5%;
  }

  .emptyCombo {
    margin: 0 0 0 5%;
  }

  .item {
    height: 130px;
    width: 330px;
    margin: 10px 15px 10px 1px;
  }

  .image {
    width: 145px;
    height: 145px;
  }
}
/* ---------- Desktop, END ---------- */
