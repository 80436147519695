@import "../../global";

/* ---------- Mobile, START ---------- */
.wrapperStories {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  width: 100vw;
}

.close {
  position: absolute;
  top: 20px;
  right: 5px;
  padding: 5px;
  z-index: 99999;
  cursor: pointer;
  font-size: 20px;
  color: $color-lightGray;
  &:hover {
    color: $color-black;
  }
}

.wrapperLink {
  display: flex;
  justify-content: center;
  margin: 0 0 50px 0;
}

.back {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 100%;
  z-index: 9999;
}

.next {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
  height: 100%;
  z-index: 9999;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 40px;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  background-color: $color-orange;
  border-radius: 30px;
  color: $color-white;
}

.preview {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.emptyStory {
  width: 150px;
  height: 200px;
  margin: 0 0 0 2.5%;
}

.previewImage {
  width: 150px;
  height: 200px;
  margin: 0 10px 0 0;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.previewImageNew {
  width: 150px;
  height: 200px;
  margin: 0 10px 0 0;
  border-radius: 20px;
  padding: 2px;
  border: 2px solid $color-orange;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.previewImage:hover,
.previewImageNew:hover {
  transition: 0.3s ease-in;
  transform: scale(1.02);
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
  .wrapperStories {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      $color-lightSilver,
      $color-white-transparent 90%
    );
    backdrop-filter: blur(5px);
  }

  .wrapperLink {
    margin: 0 0 20px 0;
  }

  .close {
    top: 10px;
    right: 10px;
    font-size: 25px;
  }

  .emptyStory {
    margin: 0 0 0 5%;
  }

  .previewImage {
    width: 200px;
    height: 250px;
    margin: 5px 20px 0 0;
  }

  .previewImageNew {
    width: 200px;
    height: 250px;
    margin: 5px 20px 0 0;
    padding: 2px;
    border: 2px solid $color-orange;
  }
}
/* ---------- Desktop, END ---------- */
