@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  background: $color-green;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  color: $color-white;
  position: absolute;
  width: 100%;
  animation-duration: 0.5s;
  transition: ease-in-out;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-top: -100%;
  }
  to {
    margin-top: 0%;
  }
}

.containerNone {
  display: none;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 10px;
}

.title {
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-lSmall;
  color: $color-white;
  font-weight: $weight-normal;
  margin: 0 0 0 10px;
}

.subTitle {
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
  color: $color-white;
  font-weight: $weight-normal;
  margin: 4px 0 0 10px;
}

.image {
  width: 100px;
  height: 30px;
}

.apps {
 display: flex;
 flex-direction: column;
 margin: 7px 12px 5px 10px;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    display: none;
  }
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
  .container {
    display: none;
  }
}

/* ---------- Desktop, END ---------- */
