/* ----- Start, Reset CSS ----- */
html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;

  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* ----- Finish, Reset CSS ----- */

/* ----- Start, Fonts CSS ----- */
@font-face {
  font-family: 'OpenSans_400';
  src: url('./assets/fonts/OpenSans_400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans_600';
  src: url('./assets/fonts/OpenSans_600.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans_700';
  src: url('./assets/fonts/OpenSans_700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/montserrat_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/montserrat_500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/montserrat_600.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/montserrat_800.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
/* ----- Finish, Fonts CSS ----- */

/* ----- Start, Variables CSS ----- */

// --- Colors ---
$color-black: #000000;
$color-black-transparent: rgba(0, 0, 0, 0.64);
$color-white: #ffffff;
$color-white-transparent: rgba(255, 255, 255, 0.75);
$color-darkWhite: #e0e0e0;
$color-lightenSilver: #c0c0c0;
$color-lightSilver: #f3f3f7;
$color-silver: #969cab;
$color-darkSilver: #5c6370;
$color-lightGray: #4e4e4e;
$color-gray: #323232;
$color-darkGray: #181818;
$color-lightGreen: #e8fce8;
$color-mediumGreen: #82be8d;
$color-green: #289b41;
$color-deepGreen: #2e7e3b;
$color-darkGreen: #006633;
$color-lightOrange: #ffa014;
$color-orange: #f05023;
$color-red: #e51c31;

// --- Font size ---
$size-xSmall: 12px;
$size-small: 14px;
$size-lSmall: 16px;
$size-medium: 18px;
$size-lMedium: 22px;
$size-XlMedium: 28px;
$size-large: 36px;

// --- Font weight ---
$weight-normal: 400;
$weight-boldNormal: 500;
$weight-semiBold: 600;
$weight-bold: 700;

/* ----- Finish, Variables CSS ----- */

body {
  background-color: #ffffff;
}
