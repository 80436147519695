@import "../../../../global";

/* ---------- Mobile, START ---------- */
.button {
  margin: 0 5%;
  width: 90%;
  padding: 18px 70px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-small;
  line-height: $size-small;
  color: $color-white;
  border: 0;
  background: $color-green;
  border-radius: 22px;
  box-shadow: 0 4px 15px rgba(53, 148, 68, 0.5);
  
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}

@media screen and (min-width: 1420px) {
}

/* ---------- Desktop, END ---------- */
