@import "../../global";

/* ---------- Mobile, START ---------- */
.mobile {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.city {
  margin: 40px 0 0 0;
  font-family: Montserrat, OpenSans_400, sans-serif;
  font-weight: $weight-boldNormal;
  font-size: $size-small;
  text-decoration: none;
  color: #000;
}
.logo {
  display: flex;
  width: 238px;
  height: 50px;
  margin: 20px auto 0 auto;
}

.desktop {
  display: none;
}

@media screen and (min-width: 480px) {
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .burgerMenuLogo .logo {
    display: none;
  }

  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4%;
    height: 70px;
  }

  .city {
    position: absolute;
    margin: -20px 0 0 60px;
  }

  .phones {
    display: flex;
    font-family: Montserrat, OpenSans_400, sans-serif;
    font-weight: $weight-boldNormal;
    font-size: $size-small;
    margin: 30px 0 0 0;
    background-color: #fff;
  }

  .phone {
    display: flex;
    background-color: #fff;
    margin: 0 5px 0 0;
    text-decoration: none;
    color: $color-darkSilver;
    transition: color 0.25s;
    &:hover {
      color: $color-green;
    }
    &:last-of-type {
      margin: 0;
    }
  }

  .phoneIcon {
    display: block;
    overflow: hidden;
    margin: 0 4px 0 0;
    width: 14px;
  }

  .phoneIcon svg {
    position: relative;
    color: $color-darkSilver;
    font-size: $size-medium;
    left: 14px;
    margin: 2px 0 0 0;
    transform: rotate(90deg);
    transition: 0.25s ease-in;
  }

  .phone:hover .phoneIcon svg {
    color: $color-green;
    left: 0px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .collapseOpen {
    height: 45px;
    margin: 30px 0 0 0;
    z-index: 10;
  }

  .collapseClose {
    height: 15px;
    overflow: hidden;
  }

  .collapseControlOpen {
    margin: 30px 0 0 0;
  }

  .collapseControlClose {
  }
}

@media screen and (min-width: 1440px) {
  .phoneIcon {
    margin: 0 6px 0 0;
  }
}
/* ---------- Desktop, END ---------- */
