@import '../../global';

/* ---------- Mobile, START ---------- */

.description {
  font-family: 'Montserrat', 'OpenSans_400', sans-serif;
  color: $color-silver;
  font-weight: $weight-normal;
  font-size: $size-small;
  line-height: 24px;
  margin: 50px 0;
  height: 300px;
  overflow-y: scroll;
  padding: 16px;
  width: 90%;
  margin: 0 auto;
}

.description {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
}

.description::-webkit-scrollbar {
  width: 7px;
  background-color: $color-darkWhite;
}

.description::-webkit-scrollbar-thumb {
  background-color: $color-green;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */
