@import "../../../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
  padding: 30px 15px;
  background-color: $color-white;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  margin: 0 0 10px 0;
  font-size: $size-small;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
}

.number {
  margin: 5px 0;
  background: $color-lightSilver;
  color: $color-darkSilver;
  width: 145px;
  border: 0;
  font-size: $size-lSmall;
  line-height: $size-lSmall;
  padding: 6px 16px;
  border-radius: 12px;
}

.number:focus{
  outline: 2px solid $color-green;
}


.label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: $color-green;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  font-size: $size-lSmall;
  font-weight: $weight-normal;
}

.subTitle {
  margin: 5px 0 0 0;
  color: $color-black;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  font-size: $size-lSmall;
  font-weight: $weight-normal;
}

.text {
  margin: 2px 0 0 0;
  color: $color-silver;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  font-size: $size-xSmall;
  font-weight: $weight-normal;
}

.label .checkbox+.info>.newChecknox {
  display: block;
  width: 21px;
  height:21px;
  background: url('../../../../assets/icons/check_box_blank.svg');
}

.label .checkbox:checked+.info>.newChecknox {
  background: url('../../../../assets/icons/check_box.svg');
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 15px 0 0 0;
}

.checkbox {
  position: absolute;
  opacity: 0;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */

/* ---------- Desktop, END ---------- */
