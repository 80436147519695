@import "../../global";

/* ---------- Mobile, START ---------- */

@mixin badge {
  position: absolute;
  padding: 6px 10px;
  border-radius: 14px;
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  text-transform: uppercase;
  color: $color-white;
  font-weight: $weight-semiBold;
  background-color: $color-deepGreen;
  font-size: 7px;
  z-index: 2;
}

.badgeDish {
  @include badge;
  top: 30px;
  right: 30px;
}
.badgeHalf {
  @include badge;
  top: 10px;
  right: 10px;
}

.badge {
  @include badge;
  top: 10px;
  right: 10px;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {

}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .badge {
    top:60px;
    right: 60px;
    font-size: $size-xSmall;
  }

  .badgeHalf {
    top: 10px;
    right: 10px;
    font-size: 8px;
  }

  .badgeDish {
    top: 50px;
    right: 60px;
    font-size: 12px;
  }
}
/* ---------- Desktop, END ---------- */
