@import '../../global';

/* ---------- Mobile, START ---------- */
.container {
}

.text {
  &:hover {
    color: $color-white;
  }
}

.footer {
  padding: 0 5%;
  background: $color-darkGray;
}

@mixin callUsMixin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  background-color: $color-white;
  box-shadow: 0 14px 20px rgba(0, 0, 0, 0.15);
  z-index: 3;
}

.callUs {
  @include callUsMixin;
  position: fixed;
  left: 30px;
  bottom: 30px;
}

.callUsIcon {
  font-size: 35px;
  color: $color-deepGreen;
}

.team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5%;
  background: $color-green;
  text-decoration: none;
}

.teamIconPeople {
  display: none;
}

.teamTitle {
  color: $color-white;
  font-size: $size-lSmall;
  font-family: Montserrat, OpenSans_400, sans-serif;
  line-height: 1.5;
}

.teamButton {
  display: none;
  background-color: $color-white;
}

.teamIconArrow {
  font-size: $size-lMedium;
  color: $color-white;
}

.email {
  padding: 15px 0;
  color: $color-white;
  font-family: Montserrat, OpenSans_400, sans-serif;
  font-size: $size-small;
}

.desktopMobApps {
  display: none;
}

.strip {
  height: 1px;
  background: $color-gray;
}

.block {
  padding: 20px 0;
}

.title {
  margin: 0 0 10px 0;
  color: $color-white;
  font-size: $size-medium;
  font-family: Montserrat, OpenSans_400, sans-serif;
  font-weight: $weight-boldNormal;
}

.link {
  text-decoration: none;
}

.text {
  color: $color-lightenSilver;
  font-family: Montserrat, OpenSans_400, sans-serif;
  font-weight: $weight-normal;
  font-size: $size-lSmall;
  line-height: 25px;
}

.phone {
  display: flex;
  margin: 0 20px 0 0;
  text-decoration: none;
  color: $color-darkSilver;
  transition: color 0.25s;
  &:hover {
    color: $color-green;
  }
  &:last-of-type {
    margin: 0;
  }
}

.copyright {
  display: flex;
  padding: 15px 0 25px 0;
}

.copyrightText1 {
  font-weight: $weight-semiBold;
  font-family: Montserrat, OpenSans_400, sans-serif;
  color: $color-gray;
  letter-spacing: 1px;
}

.copyrightText2 {
  padding: 0 0 0 5px;
  font-family: Montserrat, OpenSans_400, sans-serif;
  color: $color-gray;
}

.social {
  display: flex;
  flex-direction: row;
  margin: 15px 0 5px 0;
}

.socialIconBackground {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px 5px 0;
  background-color: $color-gray;
  border-radius: 5px;
}

.socialIconFacebook {
  height: 14px;
  width: 14px;
}

.socialIconInstagram {
  height: 14px;
  width: 14px;
}

.socialIconYoutube {
  height: 12px;
  width: 18px;
}

.apps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.appsImage {
  height: 50px;
}

.address {
  display: flex;
  flex-direction: row;
  background-color: $color-red;
}

@media screen and (min-width: 420px) {
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .apps {
    justify-content: flex-start;
  }

  .appsImage {
    margin: 0 20px 0 0;
  }
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .team {
    background: $color-green;
  }

  .teamTitle {
    color: $color-white;
    font-size: $size-lSmall;
  }

  .teamBlock {
    display: flex;
    align-items: center;
  }

  .teamIconPeople {
    display: block;
    height: 32px;
    width: 32px;
    margin: 0 20px 0 0;
  }

  .teamButton {
    display: block;
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    line-height: 14px;
    color: $color-green;
    font-size: $size-lSmall;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }

  .teamIconArrow {
    display: none;
  }

  .email {
    display: none;
  }

  .borderTop {
    display: none;
  }

  .desktopRow {
    display: flex;
    padding: 40px 0;
  }

  .row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .desktopMobApps {
    display: block;
  }

  .mobileApps {
    display: none;
  }

  .made {
    padding: 0;
  }

  .block {
    width: 25%;
    padding: 0 10px 0 0;
  }

  .social {
    margin: 0 0 5px 0;
  }

  .socialIconBackground {
    height: 38px;
    width: 38px;
    margin: 5px 0 5px 10px;
    &:hover {
      background: $color-darkSilver;
    }
  }

  .socialIconFacebook {
    height: 22px;
    width: 21px;
  }

  .socialIconInstagram {
    height: 22px;
    width: 22px;
  }

  .socialIconYoutube {
    height: 18px;
    width: 26px;
  }

  .appsImage {
    height: 50px;
    margin: 0 20px 0 0;
  }
}
/* ---------- Desktop, END ---------- */
