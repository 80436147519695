@import "../../global";

/* ---------- Mobile, START ---------- */
.notWork {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70px;
  margin: 20px 0;
  padding: 0 50px;
  background-color: $color-red;
  font-family: "OpenSans_400", sans-serif;
  font-weight: $weight-bold;
  font-size: $size-medium;
  color: $color-white;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */
