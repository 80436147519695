@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  background: $color-lightSilver;
  overflow-x: hidden;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0 14px 15px;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-medium;
  background: $color-white;
  border-bottom: 2px solid $color-lightSilver;
}

.block {
  background: $color-white;
  padding: 15px 15px;
  font-size: $size-small;
  font-family: "OpenSans_400", sans-serif;
}
.delivery,
.discount {
  margin: 0 0 14px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  color: $color-lightGray;
}
.freeDelivery {
  color: $color-darkSilver;
  font-size: $size-xSmall;
}
.discount {
  color: $color-green;
}

.button {
  margin: 10px 0 0 0;
  width: 100%;
  padding: 18px 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-small;
  line-height: $size-small;
  color: $color-white;
  border: 0;
  background: $color-green;
  border-radius: 22px;
  box-shadow: 0 4px 15px rgba(53, 148, 68, 0.5);
}

.payment {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  background-color: $color-white;
}

.emptyCart {
  margin: -100px 0 0 0;
  width: 200px;
  height: 200px;
}

.emptyText {
  font-size: $size-lSmall;
  font-family: "OpenSans_400", sans-serif;
  font-weight: $weight-normal;
  color: $color-darkSilver;
}

.cartDone{
  margin: -100px 0 0 0;
  font-size: 50px;
  color: $color-green;
}

.doneTitle {
  margin: 20px 0 0 0;
  font-size: 30px;
  color: $color-green;
  font-size: $size-lSmall;
  font-family: "OpenSans_400", sans-serif;
  font-weight: $weight-normal;
}

.doneText {
  margin: 7px 0 0 0;
  color: $color-darkSilver;
  font-size: $size-lSmall;
  font-family: "OpenSans_400", sans-serif;
  font-weight: $weight-normal;
}

.donePaymentText {
  text-align: center;
  margin: 15px 50px;
  color: $color-darkSilver;
  font-size: $size-lSmall;
  font-family: "OpenSans_400", sans-serif;
  font-weight: $weight-normal;
}

.buttonNotActive {
  margin: 10px 0 0 0;
  width: 100%;
  padding: 18px 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-small;
  line-height: $size-small;
  color: $color-white;
  border: 0;
  background: $color-silver;
  border-radius: 22px;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */
