@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  position: fixed;
  justify-content: center;
  width: 100%;
  bottom: 0;
  z-index: 99;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 270px;
  background: $color-lightGreen;
  border-radius: 20px 20px 0 0;
  transition: 0.25s ease-in-out;
  .text {
    display: none;
  }
}

.blockActive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 30px;
  background: $color-green;
  border-radius: 20px 20px 0 0;
  margin: 100px 0 0 0;
  transition: 0.5s ease-in-out;
}

.text {
  color: $color-white;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */
