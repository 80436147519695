@import "../../global";

/* ---------- Mobile, START ---------- */

.container {
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  color: $color-black;
  margin: 20px 0;
  padding: 0 16px;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
  .container {
    padding: 0 5%;
    margin: 0;
  }
}
/* ---------- Desktop, END ---------- */
