@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  margin: 20px 0;
  min-height: 700px;
}

.loader {
  height: 700px;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .container {
    margin: 0 0 30px 0;
    min-height: 1000px;
  }
}
/* ---------- Desktop, END ---------- */
