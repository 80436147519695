@import "../../global.scss";

/* ---------- Mobile, START ---------- */
p > b {
  font-weight: $weight-bold;
}

.container {
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  color: $color-black;
  margin: 10px 0;
  padding: 0 5%;
}

.title {
  border-top: 1px solid $color-darkWhite;
  padding: 20px 0;
  text-decoration: underline;
  font-size: $size-lMedium;
}

.content {
  font-size: $size-small;
  font-weight: $weight-normal;
  line-height: 24px;
}

.container div > p {
  margin-bottom: 16px;
}

.container ol > li {
  list-style-type: decimal;
  font-weight: $weight-boldNormal;
  font-size: $size-lSmall;
  margin: 16px 0 16px 32px;
}

.container div > ul {
  margin: 0 0 16px 16px;
}

.container ul > li {
  list-style-type: disc;
  margin-left: 32px;
}
/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
  .container {
    // padding: 20px 5% 30px 5%;
  }
}
/* ---------- Desktop, END ---------- */
