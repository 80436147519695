@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 0 0 10px;
  z-index: 3;
  background: $color-white;
}

.desktop {
  display: none;
}

.containerFixed {
  box-shadow: rgba(6, 5, 50, 0.04) 0px 10px 10px;
}

.nav {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: row;
  padding: 20px 0;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.item {
  white-space: nowrap;
  list-style: none;
}

.link {
  padding: 8px 16px 8px 16px;
  border-radius: 17px;
  margin: 0 15px 0 0;
  text-decoration: none;
  font-family: Montserrat, OpenSans_400, sans-serif;
  font-size: $size-small;
  line-height: 14px;
  background: $color-lightSilver;
  color: $color-black;
}

.activeLink {
  padding: 8px 16px 8px 16px;
  border-radius: 17px;
  margin: 0 15px 0 0;
  text-decoration: none;
  font-family: Montserrat, OpenSans_400, sans-serif;
  font-size: $size-small;
  line-height: 14px;
  background: $color-lightSilver;
  color: $color-white;
  background-color: $color-green;
}

.miniLogo {
  margin: 9px 0 0 0;
  overflow: hidden;
  height: 36px;
  width: 0px;
  transition: 0.5s ease-in-out width;
  padding: 0 0 10px 0;
}

.miniLogo img {
  width: 36px;
  margin: 0 0 0 -36px;
  transform: rotate(-180deg);
  transition: 0.5s ease-in-out;
}

.animation {
  transition: 0.5s ease-in-out width;
  width: auto;
  padding-right: 10px;
}
.animation img {
  margin: 0px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {

}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: flex;
  }

  .container {
    width: 90%;
    padding: 0 5%;
  }

  .nav {
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border: none;
  }

  .item {
    padding: 0 10px 0 0;
    font-weight: $weight-boldNormal;
  }

  .link {
    padding: 0;
    text-decoration: none;
    color: $color-black;
    transition: color 0.25s;
    font-size: $size-small;
    background: $color-white;
    
  }

  .activeLink {
    color: $color-green;
    padding: 0;
    text-decoration: none;
    transition: color 0.25s;
    font-size: $size-small;
    background: $color-white;
  }


}
/* ---------- Desktop, END ---------- */
