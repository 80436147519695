@import "../../../../global";

/* ---------- Mobile, START ---------- */
.container {
}

.subTitle {
  padding: 14px 0 14px 15px;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-medium;
  background: $color-white;
}

.dish {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
  padding: 0 15px;
  background: $color-white;
}

.dishInfoWrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.imageHalves {
  width: 50px;
  height: 100px;
  margin: 0 1px 0 0;
}

.imageCombo {
  width: 100px;
  height: 100px;
}

.imageDish {
  width: 100px;
  height: 100px;
}

.dishInfo {
  margin: 0 0 0 15px;
}

.dishName {
  margin: 0 0 10px 0;
  font-size: $size-small;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
}

.description {
  margin: 0 0 8px 0;
  font-size: $size-xSmall;
  color: $color-silver;
  line-height: 16px;
  font-family: "OpenSans_400", sans-serif;
}

.subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  font-family: "OpenSans_400", sans-serif;
  border-top: 2px solid $color-lightSilver;
}

.quantity {
  display: flex;
}

.quantity :first-child {
  background: $color-lightSilver;
  color: $color-lightGray;
  border: 0;
  font-size: $size-medium;
  line-height: 32px;
  padding: 0 10px 0 14px;
  border-radius: 16px 0 0 16px;
  &:hover {
    color: $color-black;
  }
  &:focus {
    color: $color-darkSilver;
  }
}

.quantity :last-child {
  background: $color-lightSilver;
  border: 0;
  font-size: $size-medium;
  line-height: 32px;
  padding: 0 14px 0 10px;
  border-radius: 0 16px 16px 0;
  color: $color-darkSilver;
  &:hover {
    color: $color-black;
  }
  &:focus {
    color: $color-darkSilver;
  }
}

.quantityInput {
  background: $color-lightSilver;
  border: 0;
  text-align: center;
  width: 36px;
  line-height: 32px;
  color: $color-darkSilver;
}

.additiveTitle {
  text-align: center;
  margin: 30px 0 15px 0;
  font-size: $size-small;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
}

.additiveItem {
  display: flex;
  align-items: center;
  width: 280px;
  height: 100px;
  padding: 10px 12px;
  margin: 10px 0 30px 15px;
  border-radius: 12px;
  box-shadow: rgba(6, 5, 15, 0.15) 0 2px 15px -3px;
  background-color: $color-white;
}

.additiveImage {
  width: 100px;
  height: 100px;
}

.additiveName {
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  font-size: $size-lSmall;
  font-weight: $weight-normal;
}

.additiveBlock {
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  font-size: $size-small;
  line-height: 21px;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
}
/* ---------- Desktop, END ---------- */

/* ---------- Desktop, END ---------- */
