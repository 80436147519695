@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.leftBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
}

.rightBlock {
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 2;
  justify-content: space-between;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 12px 0;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-lMedium;
  line-height: 1.3;
}

.title h2 {
  margin: 0 10px 0 0;
}

.dish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image {
  overflow-y: hidden;
  height: 300px;
  width: auto;
  top: 0;
  z-index: 1;
}

.images {
  width: 135px;
  height: 135px;
  border: 1px solid $color-darkWhite;
  border-radius: 10px;
}

.imagesActive {
  width: 135px;
  height: 135px;
  border: 2px solid $color-green;
  border-radius: 10px;
}

.coverContent {
  height: min-content;
}

.coverPercent {
  height: 100%;
}

.infoBlock {
  display: flex;
  // height: min-content;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px 0 15px;
  background: linear-gradient(
    to top,
    $color-white,
    $color-white-transparent 90%
  );
  backdrop-filter: blur(20px);
}

.parameters {
  margin: 0 0 10px 0;
  color: $color-darkSilver;
  font-family: "OpenSans_400", sans-serif;
}

.description {
  font-size: $size-small;
  line-height: 20px;
  flex: 1 1 auto;
  font-family: "OpenSans_400", sans-serif;
}

.ingredientsTitle {
  display: flex;
  margin: 22px 0 10px 0;
  font-size: $size-lSmall;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
}

.arrow {
  font-size: $size-medium;
  margin: 0 0 0 10px;
}

.ingredients {
  display: flex;
  flex-wrap: wrap;
}

.ingredient {
  margin: 0 5px 10px 0;
  padding: 3px 8px 4px 8px;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
  line-height: 14px;
  color: $color-orange;
  border: 1px solid $color-orange;
  border-radius: 8px;
  white-space: nowrap;
}

.defaultIngredient {
  margin: 0 5px 10px 0;
  padding: 3px 8px 4px 8px;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
  line-height: 14px;
  color: $color-silver;
  border: 1px solid $color-silver;
  border-radius: 8px;
  white-space: nowrap;
}

.deleteIngredient {
  margin: 0 5px 10px 0;
  padding: 3px 8px 4px 8px;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
  line-height: 14px;
  color: $color-green;
  border: 1px solid $color-green;
  border-radius: 8px;
  white-space: nowrap;
  text-decoration: line-through;
}

.deleteIngredient::before {
  content: "+";
  display: block;
  float: left;
  width: 10px;
}

.ingredient::before {
  content: "-";
  display: block;
  float: left;
  width: 10px;
}

.addIngrediets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}

.addIngrediets li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: calc(30.5% - 6px);
  margin: 0 8px 10px 6px;
  padding: 15px 0;
  box-shadow: 0 0 15px rgba(20, 20, 40, 0.1);
  background: $color-white;
  border-radius: 15px;
  text-align: center;
  transition: 0.2s ease-in;
}
.addInfo {
  display: flex;
  flex-direction: column;
}

.addIngrediets li:hover {
  box-shadow: 0 0 15px rgba(30, 30, 50, 0.2);
  transition: 0.2s ease-in;
}

.addCurrent {
  border: 2px solid $color-orange;
}

.addCheck {
  position: absolute;
  margin: -12px 0 0 2px;
  color: $color-orange;
}

.addImage {
  width: 100%;
}

.name {
  text-align: center;
  max-width: 140px;
  max-height: 25px;
  overflow: hidden;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-xSmall;
}

.addTitle {
  font-size: $size-xSmall;
  line-height: 16px;
  margin: 8px 0 6px 0;
  font-family: "OpenSans_400", sans-serif;
}

.addPrice {
  font-family: "Montserrat", sans-serif;
  font-size: $size-xSmall;
  font-weight: $weight-semiBold;
}
.footer {
  position: sticky;
  box-sizing: border-box;
  z-index: 15;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background: $color-white;
}

.addtoCart {
  padding: 12px 0;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: $size-lSmall;
  background: $color-green;
  box-shadow: 0 5px 15px rgba(53, 148, 68, 0.5);
  color: $color-white;
  border-radius: 25px;
  border: none;
}

.addtoCart:hover {
  background: $color-deepGreen;
  transition: 0.1s ease-in;
}

.addtoCart:active {
  color: $color-mediumGreen;
}

.price {
  margin: 8px 0 0 0;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-xSmall;
}

.longDescription {
  font-family: "Montserrat", "OpenSans_400", sans-serif;
  color: $color-silver;
  font-weight: $weight-normal;
  font-size: $size-small;
  line-height: 24px;
  padding: 16px 0;
  width: 90%;
  margin: 0 auto;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .title {
    color: $color-lightGray;
  }
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
    height: inherit;
  }

  .leftBlock {
    margin: 0;
    padding: 0 15px 0 15px;
    width: 500px;
  }

  .title {
    color: $color-black;
    margin: 0 0 12px 0;
    font-size: $size-XlMedium;
  }

  .coverContent {
    overflow-y: auto;
    border-radius: 0 20px 20px 0;
  }

  .coverPercent {
    overflow-y: auto;
  }

  .infoBlock {
    margin: 0 auto;
    padding: 15px 30px 10px 30px;
    width: 350px;
    backdrop-filter: none;
  }

  .infoIcon {
    font-size: $size-medium;
  }

  .addIngrediets {
    margin: 0;
  }

  .addCheck {
    position: inherit;
  }

  .parameters {
    margin: -20px 0 10px 0;
  }

  .footer {
    box-sizing: border-box;
    z-index: 15;
    width: 100%;
    padding: 15px 30px;
  }

  .addtoCart {
    width: 100%;
    transition: 0.1s ease-in;
  }

  .image {
    width: 470px;
    height: auto;
  }

  .longDescription {
    width: 80%;
  }
}
/* ---------- Desktop, END ---------- */
