@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-between;
  font-family: "OpenSans_400", sans-serif;
  border-radius: 19px;
  background: $color-lightSilver;
  padding: 4px;
  width: 100%;
  height:32px;
}

.board {
  width: 33.33%;
  cursor: pointer;
  padding: 6px 0;
  vertical-align: center;
  text-align: center;
  border-radius: 15px;
  font-size: $size-xSmall;
  line-height: 12px;
  z-index: 9;
}

.activeBoard {
  width: 33.33%;
  padding: 6px 0;
  vertical-align: center;
  text-align: center;
  border-radius: 15px;
  font-size: $size-xSmall;
  line-height: 12px;
  z-index: 1;
  cursor: pointer;
}

.hoverColor{
  width: 33.33%;
  height: 24px;
  margin:-24px 0 0 0;
  transform: translateX(0px);
  background: $color-white;
  border-radius: 15px;
  transition: all ease-in 0.3s;
  box-shadow: 0 2px 8px rgba(30, 30, 55, 0.1);
}

.board:nth-of-type(1):hover ~ .hoverColor{
  transform: translateX(0);
  transition: all ease-in 0.3s;
}

.board:nth-of-type(2):hover ~ .hoverColor{
  transform: translateX(100%);
  transition: all ease-in 0.3s;
}
.board:nth-of-type(3):hover ~ .hoverColor{
  transform: translateX(200%);
  transition: all ease-in 0.3s;
}

.activeBoard:nth-of-type(1) ~ .hoverColor{
  transform: translateX(0);
}

.activeBoard:nth-of-type(2) ~ .hoverColor{
  transform: translateX(100%);
}

.activeBoard:nth-of-type(3) ~ .hoverColor{
  transform: translateX(200%);
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
}
/* ---------- Desktop, END ---------- */

/* ---------- Desktop, END ---------- */
