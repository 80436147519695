@import "../../global";

/* ---------- Mobile, START ---------- */
.infoIcon {
  width: 30px;
  margin: 5px 0 0 0;
}

.nutritional {
  position: absolute;
  z-index: 10;
  margin: 10px 0 0 -207px;
  width: 210px;
  padding: 15px;
  background: $color-gray;
  color: $color-white;
  font-family: "OpenSans_400", sans-serif;
  font-size: $size-small;
  border-radius: 10px;
}

.nutritional > div {
  margin: 0 0 15px 0;
}

.nutriValue {
  color: $color-lightenSilver;
}

.nutritional > div:last-child {
  margin: 0;
}

.nutritional::before {
  content: "";
  display: block;
  position: absolute;
  transform: rotate(45deg);
  margin: -20px 0 0 195px;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  background: $color-gray;
}
.nutritional p {
  font-size: $size-xSmall;
  line-height: 1.8;
}

.nutritional p {
  display: flex;
  justify-content: space-between;
}
p.title {
  font-size: $size-small;
}

p.titleHide {
  display: none;
}

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) {
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1025px) {
  .nutritional {
    margin: 10px 0 0 -190px;
  }

  .nutritional::before {
    content: "";
    margin: -20px 0 0 180px;
  }
}
/* ---------- Desktop, END ---------- */
