@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  justify-content: center;
  :last-child {
    margin: 0;
  }
}

.select {
  position: absolute;
  top: 40px;
  right: 20px;
  border: none;
}

.item:nth-child(2) {
  margin: 0 9px;
}

@mixin languageDefault {
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: $size-lSmall;
  color: $color-white;
  font-family: Montserrat, OpenSans_400, sans-serif;
}

.language {
  @include languageDefault;
}

.activeLanguage {
  @include languageDefault;
  color: $color-green;
}

.list {
  display: none;
}



/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .languagesContainer div {
    margin: 0 5px 0 0;
  }
}

/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .language {
    @include languageDefault;
    color: $color-silver;
    cursor: pointer;
  }

  .language:hover {
    color: $color-green;
  }

  .activeLanguage {
    @include languageDefault;
    color: $color-silver;
    cursor: pointer;
    background: $color-lightSilver;
  }

  .select {
    display: none;
  }

  .list {
    display: flex;
  }
}
/* ---------- Desktop, END ---------- */
