@import "../../global";

/* ---------- Mobile, START ---------- */
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // margin: -70px 0 0 0;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-white;
}

.titleTop {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 0;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-medium;
  line-height: 1.3;
}

.title {
  padding: 25px 50px 12px 90px;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
  font-size: $size-medium;
  line-height: 1.3;
  background-color: $color-white;
}

.dishes {
  display: flex;
  margin: 0 auto;
  flex: 1 1 auto;
  width: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow-y: scroll;
}

.dish,
.dishActive {
  width: 33%;
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  background-color: $color-white;
  position: relative;
}

.wrapperImage {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.dishActive .wrapperImage {
  border: 1px solid $color-orange;
}

.dish .images,
.dishActive .images {
  width: 100%;
}

.transparencyLeft {
  // margin: -350px 0 0 0;
  width: 50%;
  // height: 350px;
  opacity: 0.8;
  background: $color-white;
}

.transparencyRight {
  // margin: -350px 0 0 50%;
  // width: 50%;
  // height: 350px;
  opacity: 0.8;
  background: $color-white;
}

.name {
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-xSmall;
  line-height: 1;
  margin: 6px 0 4px 0;
}

.dishActive .name,
.dishActive .price {
  color: $color-orange;
}

.price {
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-xSmall;
}

.footerWrapper {
  position: sticky;
  align-self: flex-end;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  margin: 0;
  background: rgba(255, 255, 255, 0.7);
  bottom: 0;
  z-index: 9;
  backdrop-filter: blur(15px);
}

.joinTotal {
  display: flex;
}

.joinTotalHide {
  display: none;
}

.joinDish {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  color: $color-black;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-normal;
  font-size: $size-xSmall;
  line-height: 1;
  margin: 0 0 12px 0;
}

.joinPrice {
  margin: 5px 0 0 0;
  color: $color-silver;
}

.addToCart {
  padding: 12px 0;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: $size-lSmall;
  background: $color-green;
  box-shadow: 0 5px 15px rgba(53, 148, 68, 0.5);
  color: $color-white;
  border-radius: 25px;
  border: none;
  transition: 0.1s ease-in;
  &:hover {
    background: $color-deepGreen;
    transition: 0.1s ease-in;
  }
  &:active {
    color: $color-mediumGreen;
  }
}

.addToCartDisabled {
  padding: 12px 0;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: $size-lSmall;
  background: $color-lightenSilver;
  color: $color-white;
  border-radius: 25px;
  border: none;
}

.preview {
  display: block;
}

.cards {
  display: block;
}

.toggleContainer {
  display: none;
  margin-bottom: 38px;
}

.toggleContainerActive {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Half Preview Start*/

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 5px 15px;
  width: auto;
}

.preview {
  height: 300px;
  width: 300px; 
  display: flex;
  margin: 0 auto;
  background-image: url("../../assets/images/defaultPizza.png");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
}

.leftWrapper,
.rightWrapper {
  width: 50%;
  overflow: hidden;
}

.leftWrapper img,
.rightWrapper img {
  width: 190%;
}

.leftWrapper img {
  margin: 4px 0 0 0;
}

.rightWrapper img {
  margin: 4px 0 0 -88%;
}

/* Half Preview End*/

/* Cards Preview Start*/

.cards {
  display: block;
}

.rigthContant {
  overflow: scroll;
}

.card {
  width: auto;
  display: flex;
  min-height: 105px;
  margin: 15px 0 0 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 5px 8px rgba(92, 99, 112, 0.15);
  background-color: $color-white;
}

.blank {
  height: 105px;
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  padding: 10px 0 10px 120px;
  font-size: $size-small;
  line-height: 1.3;
  color: $color-silver;
  font-family: "Montserrat", sans-serif;
  border-radius: 10px;
  box-shadow: 0 5px 8px rgba(92, 99, 112, 0.15);
  background-image: url("../../assets/images/defaultPizza.png");
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: 15px 22px;
  background-color: $color-white;
}

.cards {
  flex: 1 1 auto;
  margin: 0 0 15px 0;
  position: relative;
}

.card .imageContainer::before {
  content: "";
  position: absolute;
  z-index: 8;
  display: block;
  width: 50px;
  height: 100px;
  background-color: $color-white-transparent;
}

.card:first-of-type .imageContainer::before {
  margin: 0 0 0 50px;
}

.card:last-of-type .imageContainer::before {
  margin: 0 50px 0 0;
}

.card .name {
  font-weight: $weight-semiBold;
}

.description {
  margin: 0 0 10px 0;
  color: $color-darkSilver;
  font-size: $size-xSmall;
  font-family: "OpenSans_400", sans-serif;
  line-height: 1.5;
  align-self: flex-start;
}

.imageContainer {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 13px 0 0;
}

.image {
  height: 100%;
  width: auto;
}

.info {
  width: calc(100% - 100px);
}

.ingredientsTitle {
  display: flex;
  margin: 15px 0 0 0;
  font-size: $size-lSmall;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: $weight-semiBold;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .dishes {
    width: 480px;
  }
}

/* Cards Preview End*/

/* ---------- Mobile, END ---------- */

/* ---------- Tablet, START ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dishes {
    width: 768px;
  }
}
/* ---------- Tablet, END ---------- */

/* ---------- Desktop, START ---------- */
@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    // height: inherit;
    margin: 0;
  }
  
  .leftSide {
    margin: 0;
    padding: 15px 0 0 0;
    max-width: 480px;
  }

  .title {
    padding: 0 15px 10px 15px;
    text-align: center;
    font-size: $size-medium;
    line-height: 1.3;
  }

  .dishes {
    overflow-x: hidden;
    width: 470px;
    height: 624px;
    padding: 0;
    margin: 0 7.5px 0;
  }

  .wrapperImage {
    box-sizing: border-box;
    border: 1px solid $color-white;
  }

  .dish:hover .wrapperImage {
    transition: all 0.3s ease-in-out;
    padding: 3px;
  }

  .dishActive .wrapperImage {
    padding: 8px;
  }

  .joinTotal {
    display: none;
  }

  .rightSide {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 30px 30px;
    width: 460px;
    box-sizing: border-box;
  }

  .preview {
    height: 200px;
    width: 400px;
    background-size: 50%;
  }

  .leftWrapper img,
  .rightWrapper img {
    width: 200px;
    height: 200px;
  }

  .leftWrapper,
  .rightWrapper {
    width: 200px;
    height: 200px;
  }

  .leftWrapper img {
    margin: 0 0 0 50%;
  }

  .rightWrapper img {
    margin: 0 0 0 -50%;
  }

  .divider {
    flex: 1 1 auto;
  }

  .footerWrapper {
    position: relative;
    padding: 15px 0 0 0;
    margin: 0;
    background: rgba(255, 255, 255, 1);
    backdrop-filter: none;
  }

  .toggleContainer {
    display: block;
    margin: 0;
  }

  .toggleContainerActive {
    margin-bottom: 0;
  }

  .image {
    width: 100%;
    height: auto;
    position: static;
  }
}

/* ---------- Desktop, END ---------- */